import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/ldy",
        name: "ldy",
        component: () => import("../views/ldyView.vue"),
    },
    {
        path: "/ldyd",
        name: "ldyd",
        component: () => import("../views/ldydView.vue"),
    },
    {
        path: "/blank",
        name: "blank",
        component: () => import("../views/BlankView.vue"),
    },
    {
        path: "/addDL",
        name: "addDL",
        component: () => import("../views/AdddlView.vue"),
    },
    {
        path: "/addQd",
        name: "addQd",
        component: () => import("../views/AddqdView.vue"),
    },
    {
        path: "/agentShare",
        name: "/agentShare",
        component: () => import("../views/agentShare.vue"),
    },
    {
        path: "/agentPay",
        name: "/agentPay",
        component: () => import("../views/agentPay.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
